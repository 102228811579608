import React from "react"
import "../components/layout.scss"
import { useIntl } from "gatsby-plugin-intl"

function Thankyou(props) {
  const intl = useIntl()
  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <h1 className="text-white font-weight-bold">
              {intl.formatMessage({ id: "thank_you_1" })}
            </h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-8 align-self-baseline">
            <h3 className="text-white font-weight-bold">
              {intl.formatMessage({ id: "thank_you_2" })}
            </h3>
            <h3 className="text-white font-weight-bold mb-5">
              {intl.formatMessage({ id: "thank_you_3" })}
            </h3>
            <a href="/#page-top">
              <div className="btn btn-primary btn-xl js-scroll-trigger">
                {intl.formatMessage({ id: "thank_you_button" })}
              </div>
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Thankyou
